.student-item {
  height: auto;
  margin: 10px;

  img {
    max-width: 240px;
    height: auto;
    width: 100%;
    padding: 10px;
  }
}

.react-multi-carousel-list {
  background-color: white;
  margin-top: 40px;
}

.image-carousel {
  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.1);
}

.text-carousel {
  margin-top: 0;
  width: auto;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  
  li {
    width: 161px !important;
  }

  p {
    font-size: 15px;
    font-family: 'BebasNeue', sans-serif;
    letter-spacing: 0.5px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    width: 289px;
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .text-carousel {
    li {
      width: 289px !important;
    }

    p {
      font-size: 28px;
    }
  }
}