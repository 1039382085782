.map-container{
  width: 90%;
  margin: 0 auto;

  h1{
    font-family: 'BebasNeue', sans-serif;
    font-size: 120px;
    font-weight: bold;
    line-height: 0.83;
    text-align: center;

    margin: 50px 0;
  }

  .map{
    margin: 50px 0;
    position: relative;


    &:first-child{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0;
    }

  }
}