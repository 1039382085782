
.more-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;

  .MuiAutocomplete-root, .MuiFormControl-root, .MuiInputBase-root {
    background-color: #ffffff;
    height: 40px;
    width: 295px;

    fieldset{
      border-color: #d4d4d4 !important;
      height: 40px !important;
    }

    input {
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      height: 35px !important;
    }

    .MuiAutocomplete-clearIndicator {
      visibility: visible !important;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
      padding: 2px;
    }
  }

  .MuiInputBase-root {
    padding-top: 7px !important;
  }

  .MuiAutocomplete-root:first-child {
    margin-bottom: 20px;
  }
}

.feed-container {
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
  min-height: 100vh;

  .MuiAutocomplete-root, .MuiFormControl-root, .MuiInputBase-root {
    background-color: #ffffff;
    height: 40px;
    width: 100%;

    fieldset{
      border-color: #d4d4d4 !important;
    }
  
    input {
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
    }
  
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
      padding: 2px;
    }

    .MuiAutocomplete-clearIndicator:visited {
      visibility: visible !important;
    }
  }
  
  .MuiInputBase-root::before{
    content: url("../../assets/search-icon.svg");
    width: 15px;
    height: 15px;
    margin: 0 5px;
  }

  .feed {
    max-width: 1240px;
    margin: auto;
    display: flex;
    flex-direction: row;

    > div:first-of-type:not(.feed-item) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-grow: 1;

      &::after {
        content: "" !important;
        flex: auto !important;
      }
    }
  }

  .feed-content {
    max-width: 1240px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .yearbook-header {
      font-family: 'BebasNeue', sans-serif;
      text-align: center;
      font-size: 38px;
      font-weight: bold;
      line-height: 1.1;
      margin-top: 17px;
      margin-bottom: 20px;
    }

    .yearbook-description {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      line-height: 1.63;
      text-align: left;
      margin-bottom: 25px;
    }

    .counter{
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 15px 0 45px;
      align-items: center;

      ul{
        display: flex;
        align-items: flex-end;
        margin: 10px 0;
        list-style: none;
        padding: 0;

        font-family: 'BebasNeue', sans-serif;
        font-size: 50px;
        font-weight: bold;
        text-align: center;

        li{
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          width: 42px;
          height: 65px;
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
          margin: 5px;
          padding-top: 10px;
        }
      }
    }
  }

  .control-bar {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .filter-button {
      background-color: #ffffff;
      color: #000000;
      height: 40px;
      box-shadow: none;
      border: solid 1px #d4d4d4;

      &-open {
        background-color: #000000;
        color: #ffffff;
      }

      .MuiButton-label {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        
        .arrow-down, .arrow-up {
          margin-left: 10px;
        }
      }
    }

    .feed-search {
      margin-right: 10px;
      margin-bottom: 20px;
      width: 100%;

      .search {
        display: flex;
        align-items: center;
        background: #ffffff;
        width: 255px;
        height: 33px;
        border-radius: 3px;
        border: solid 1px #d4d4d4;

        &:hover {
          border: solid 1px #000000;
        }

        input{
          border: none;
          width: 100%;
          height: 30px;
          outline:none;
          font-size: 14px;

          &:focus + .search{
            border: solid 1px #000000;
          }

          &::placeholder{
            color: #5b5b5b;
          }
        }

        &::before{
          width: 15px;
          height: 15px;
          margin: 0 5px;
        }
      }
    }

    .feed-more-search {
      display: flex;
      flex-direction: row;

      .feed-filter { 
        display: flex;
      }
    }

    .feed-filter {
      max-width: 1240px;
      flex: auto !important;
      justify-items: flex-end;
      display: flex;
      justify-content: flex-end;
      display: none;

      input{
        display: none;

        &:checked + .max::before {
          content: url('../../assets/feed/icon-s_hover.svg');
          width: 30px;
          height: 30px;

        }

        &:checked + .medium::before {
          content: url('../../assets/feed/icon-m_hover.svg');
          width: 30px;
          height: 30px;

        }
        &:checked + .min::before {
          content: url('../../assets/feed/icon-l_hover.svg');
          width: 30px;
          height: 30px;

        }
        &:checked + .desktop-min::before {
          content: url('../../assets/feed/icon-xl_hover.svg');
          width: 30px;
          height: 30px;

        }
      }

      label {
        margin-left: 15px;

        &.max{
          &::before {
            content: url('../../assets/feed/icon-s.svg');
            width: 30px;
            height: 30px;
          }
          &:hover {
            &::before {
              content: url('../../assets/feed/icon-s_hover.svg');
              width: 30px;
              height: 30px;
            }
          }
        }

        &.medium{
          &::before {
            content: url('../../assets/feed/icon-m.svg');
            width: 30px;
            height: 30px;
          }
          &:hover{
            &::before {
              content: url('../../assets/feed/icon-m_hover.svg');
              width: 30px;
              height: 30px;
            }
          }
        }

        &.min{
          &::before{
            content: url('../../assets/feed/icon-l.svg');
            width: 30px;
            height: 30px;
          }

          &:hover{
            &::before {
              content: url('../../assets/feed/icon-l_hover.svg');
              width: 30px;
              height: 30px;
            }
          }
        }

        &.desktop-min{
          &::before{
            content: url('../../assets/feed/icon-xl.svg');
            width: 30px;
            height: 30px;
          }

          &:hover{
            &::before{
              content: url('../../assets/feed/icon-xl_hover.svg');
              width: 30px;
              height: 30px;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .share-overlay {
    background-color: #ffffff;
    width: 160px;
    height: 48px;
    position: fixed;
    right: 0px;
    top: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

    &-search{
      background-color: #000000;
      color: #ffffff;
      width: 267px;
    }

    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      line-height: 1.75;
    }

    .twitter {
      width: 19px;
      height: 18px;
      margin-top: 4px;

    }

    .facebook {
      width: 12px;
      height: 18px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .feed-item {
    margin-bottom: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

    &:hover{
      cursor: pointer;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.35);
    }

    .feed-text {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3px 3px 10px 3px;

      h3 {
        font-family: 'BebasNeue', sans-serif;
        margin-bottom: 5px;
      }

      p {
        font-family: 'Open Sans', sans-serif;
        margin: 0;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        word-break: break-all;
      }
    }

    .feed-image {
      width: 100%;
      height: auto;
    }
  }

  .feed-item-max {
    flex-basis: calc((100% - (3 * 8px)) / 4);
    margin: 0 4px 8px 4px;

    h3 {
      font-size: 7px;
    }

    p {
      font-size: 7.5px;
    }
  }

  .feed-item-max:nth-child(4n) {
    margin-right: 0;
  }

  .feed-item-max:nth-child(4n + 1) {
    margin-left: 0;
  }

  .feed-item-medium {
    flex-basis: calc((100% - (2 * 8px)) / 3);
    margin: 0 4px 8px 4px;

    h3 {
      font-size: 10px;
      margin-top: 5px;
    }

    p {
      font-size: 9.5px;
    }
  }

  .feed-item-medium:nth-child(3n) {
    margin-right: 0;
  }

  .feed-item-medium:nth-child(3n + 1) {
    margin-left: 0;
  }

  .feed-item-min, .feed-item-desktop-min {
    flex-basis: calc((100% - (2 * 4px)) / 2);
    margin: 0 4px 8px 4px;
    padding-bottom: 15px;

    h3 {
      font-size: 15px;
      margin-top: 8px;
    }

    p {
      font-size: 10px;
    }
  }

  .feed-item-min:nth-child(2n), .feed-item-desktop-min:nth-child(2n) {
    margin-right: 0;
  }

  .feed-item-min:nth-child(2n + 1), .feed-item-desktop-min:nth-child(2n + 1) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .more-filters {
    flex-direction: row;

    .MuiAutocomplete-root, .MuiFormControl-root, .MuiInputBase-root {
      &:first-child {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    .MuiInputBase-root {
      padding-top: 0 !important;
    }
  }

  .feed-container {
    .feed-content {
      .yearbook-header {
        text-align: center;
        font-size: 8.33vw;
        font-weight: bold;
        line-height: 0.83;
      }

      .feed-div{
        .share-overlay{
          .twitter{
            margin-top: 0;
          }
        }
      }

    }
    
    .control-bar {
      flex-direction: row;

      .feed-search {
        width: 295px;
      }

      .MuiAutocomplete-root, .MuiFormControl-root, .MuiInputBase-root {
        width: 295px;
      }

      .feed-more-search {
        .feed-filter { 
          display: none;
        }
      }

      .feed-filter { 
        display: flex;
      }
    }

    .feed-item-max {
      flex-basis: calc((100% - (8 * 8px)) / 8);
      margin: 0 4px 8px 4px;

      h3 {
        font-size: 9px;
      }
    }
  
    .feed-item-max:nth-child(8n) {
      margin-right: 0;
    }
  
    .feed-item-max:nth-child(8n + 1) {
      margin-left: 0;
    }

    .feed-item-max:nth-child(4n) {
      margin-right: 4px;
    }
  
    .feed-item-max:nth-child(4n + 1) {
      margin-left: 4px;
    }

    .feed-item-medium {
      padding-bottom: 15px;
      flex-basis: calc((100% - (4 * 8px)) / 4);
      margin: 0 4px 8px 4px;

      h3 {
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .feed-item-medium:nth-child(3n) {
      margin-right: 4px;
    }
  
    .feed-item-medium:nth-child(3n + 1) {
      margin-left: 4px;
    }

    .feed-item-min, .feed-item-desktop-min {
      flex-basis: calc((100% - (3 * 8px)) / 3);
      margin: 0 4px 8px 4px;
      padding-bottom: 24px;

      h3 {
        font-size: 26px;
        margin-top: 16px;
      }
  
      p {
        font-size: 14px;
      }
    }

    .feed-item-min:nth-child(2n), .feed-item-desktop-min:nth-child(2n) {
      margin: 4px;
    }
  
    .feed-item-min:nth-child(2n + 1), .feed-item-desktop-min:nth-child(2n + 1) {
      margin: 4px;
    }

    .feed-item-min:nth-child(3n), .feed-item-desktop-min:nth-child(3n) {
      margin-right: 0;
    }
  
    .feed-item-min:nth-child(3n + 1), .feed-item-desktop-min:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .feed-container {
    .feed-content {
      .yearbook-header {
        text-align: center;
        font-size: 120px;
        font-weight: bold;
        line-height: 0.83;
      }
  
      .yearbook-description {
        font-size: 20px;
        line-height: 1.4;
        text-align: center;
        padding-left: 6.94vw;
        padding-right: 6.94vw;
      }
    }

    .share-overlay {
      top: 90vh;
      right: 20px;

      a {
        height: 20px;
      }

      .twitter {
        width: 24px;
        height: 20px;
      }

      .facebook {
        width: 13px;
        height: 20px;
      }
    }

    .feed-item-max {
      flex-basis: calc((100% - (12 * 8px)) / 12);
      margin: 0 4px 8px 4px;
    }
  
    .feed-item-max:nth-child(12n) {
      margin-right: 0;
    }
  
    .feed-item-max:nth-child(12n + 1) {
      margin-left: 0;
    }

    .feed-item-medium {
      flex-basis: calc((100% - (6 * 8px)) / 6);
      margin: 0 4px 8px 4px;
    }
  
    .feed-item-medium:nth-child(6n) {
      margin-right: 0;
    }
  
    .feed-item-medium:nth-child(6n + 1) {
      margin-left: 0;
    }

    .feed-item-min {
      flex-basis: calc((100% - (4 * 8px)) / 4);
      margin: 0 4px 8px 4px;
    }
  
    .feed-item-min:nth-child(4n) {
      margin-right: 0 !important;
      margin-left: 4px !important;
    }
  
    .feed-item-min:nth-child(4n + 1) {
      margin-left: 0 !important;
    }

    .feed-item-min:nth-child(3n) {
      margin-right: 4px;
    }
  
    .feed-item-min:nth-child(3n + 1) {
      margin-left: 4px;
    }
  }
}