.header {
  max-width: 1440px;
  margin: auto;
  
  .hamburger {
    .bm-burger-button {
      position: relative;
      width: 18px;
      height: 15px;
    }

    .bm-burger-bars {
      background: #373a47;
      height: 15% !important;
    }

    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      top: 0;
    }

    .bm-menu {
      background: #000000;
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
      overflow-x: hidden !important;
      overflow-y: hidden !important;
    }

    .bm-item-list {
      color: #b8b7ad;
      padding: 0.8em;
    }

    .bm-item {
      display: inline-block;
      color: white;
      border: none;
      text-decoration: none;
      margin-bottom: 10px;
      font-family: 'Open Sans', sans-serif;
    }

    .bm-cross {
      background: #bdc3c7;
    }

    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      right: 0;
    } 
  }

  .header-content {
    display: flex;
    align-items: center;
    padding-top: 23px;
    padding-bottom: 23px;
    background-color: red($color: #000000);
    justify-content: space-between;
    max-width: 1240px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;

    .left-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      .logo {
        color: black;
        margin-right: 6px;
        width: 72px;
  
        img {
          width: 72px;
          height: auto;
        }
      }
  
      .header-text a {
        margin: 0;
        padding-left: 6px;
        font-size: 22px;
        font-weight: bold;
        font-family: 'BebasNeue', sans-serif;
        text-decoration: none;
      }
    }
    
    .right-content {
      .menu {
        display: none;
        justify-content: space-between;

        .active {
          text-shadow: 0px 0px 0.5px #000000;
        }
  
        a {
          font-size: 0.972vw;
          font-weight: bold;
          line-height: 0.86;
          text-decoration: none;

          &:hover{
            cursor: pointer;
            text-shadow: 0px 0px 0.5px #000000;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .header {
    .hamburger {
      .bm-burger-button {
        width: 26px;
        height: 20px;
      }
    }

    .header-content {
      padding-top: 33px;
      padding-bottom: 33px;

      .title {
        font-size: 3.402vw;
      }

      .left-content {
        .logo {
          width: 101px;
    
          img {
            width: 101px;
          }
        }

        .header-text a {
          padding-left: 15px;
          font-size: 30px;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .header .header-content {
    height: 90px;
    
    .right-content .menu {
      display: flex;
      justify-content: space-between;
      width: 300px;

      a {
        font-size: 14px;
      }
    }

    .hamburger {
      display: none;
    }
  }
}
