p {
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
}

a {
  color: #000000;
}

.grecaptcha-badge {
  right: -300px !important;
}

.headline {
  max-width: 1440px;
  margin: 100px auto;

  .headline-content {
    padding-left: 6.944vw;
    padding-right: 6.944vw;
    margin-top: 48px;
    max-width: 1440px;
    text-align: center;

    h1 {
      margin: 0;
      font-size: 3.53vw;
      line-height: 0.83;
      font-family: 'BebasNeue', sans-serif;
      text-transform: uppercase;
      justify-content: center;
      align-content: center;
      font-weight: bold;

      span {
        font-weight: bold;
      }
    }
  }
}

.MuiSnackbarContent-root {
  color: #CC0000 !important;
  background-color: #ffffff !important;
  font-size: 18px !important;
  padding: 30px !important;
}

.MuiAlert-standardError {
  color: #CC0000 !important;
  background-color: #ffffff !important;
  font-size: 18px !important;
  padding: 30px !important;

  img {
    width: 32px;
    height: 32px;
  }
}

.MuiIconButton-root {
  padding: 0 !important;
}

.MuiFormControlLabel-root {
  margin-left: 0;
}

.container {
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
}

.form-success {
  display: flex;
  flex-direction: column;

  .headline {
    margin-top: 80px;
    margin-bottom: 0;

    .headline-content {
      margin-top: 0;

      h1 {
        margin-top: 0;
        font-size: 50px;
        line-height: 0.9;
        font-weight: bold;
      }
    }
  }

  .success-text {
    text-align: center;
    margin-top: 70px;
    font-size: 20px;
    padding: 0 14.23vw;
  }
  
  .social-share {
    display: flex;
    justify-content: center;
    margin-top: 43px;
    align-items: center;
  
    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.56;
    }

    a {
      margin-left: 20px;
      &:hover{
        cursor: pointer;
      }
    }

    .twitter-icon {
      width: 30px;
      height: 25px;
    }

    .facebook-icon {
      margin-left: 20px;
      width: 17px;
      height: 31px;

      &:hover{
        cursor: pointer;
      }
    }
  }
}

.form-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1240px;
  font-family: 'Open Sans', sans-serif;

  .form-info {
    width: 36%;
    font-family: "Open Sans", sans-serif;
    color: #000000;
    margin: 40px 0;

    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-top: 0;
      font-family: 'Open Sans', sans-serif;
    }

    ul {
      font-size: 16px;
      line-height: 1.5;
      font-family: 'Open Sans', sans-serif;
      
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .form-section {
    width: 62%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    .form-container {
      margin: 45px auto;
      display: flex;
      flex-direction: column;
      width: 100%;

      h3 {
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
      }

      .message-title {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        h3 {
          margin-bottom: 0;
        }

        p{
          font-size: 10px;
          color: #9b9b9b;
        }

        .no-chars {
          color: #CC0000 ;
        }
      }

      .legal {
        border: solid 1px #cecece;
        border-radius: 3px;
        height: 322px;
        overflow-y: scroll;
        padding: 15px 20px;
      }

      .legal-checkbox {
        border: solid 1px #cecece;
        border-radius: 3px;
        padding: 15px 20px;
        margin-top: 30px;

        p {
          margin: 0 0 20px;
        }
      }

      textarea {
        height: 85px;
        width: 95%;
        border: solid 1px #cecece;
        border-radius: 3px;
        padding: 15px;
        margin: 0;
        font-size: 16px;
        outline: none;
        resize: none;
        width: 100%;
        font-family: 'Open Sans', sans-serif;

        &::placeholder {
          font-size: 16px;
          color: #9b9b9b;
        }

      }

      .accept-label {
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
      }

      .newsletter-label {
        font-size: 16px;
        line-height: 1.17;
        align-self: center;
        padding-left: 5px;
      }
    }
  }
}
.submit-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 40px;

  button {
    width: 62%;
    height: 64px;
    right: 0;
    border-radius: 2px;
    background-color: #000000;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    border: none;
    margin-bottom: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  p {
    width: 62%;
    text-align: center;
    font-size: 16px;
    margin-top: 0;
  }

  .invalid-submit {
    background-color: #d0d0d0;

    &:hover {
      cursor: initial;
    }
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  padding: 0 4.51vw 0 4.51vw;
  margin-top: 50px;

  &:first-of-type {
    margin-top: 0;
  }

  .school-label {
    margin-bottom: 5px;
  }

  .school-text {
    font-size: 12px;
    margin-top: 0;
  }

  .form-label {
    padding-left: 38px;

    .MuiTypography-root {
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 1.17;
      padding-left: 5px;
    }

    .MuiFormControlLabel-root {
      margin-left: 0;
    }

    .filter-radio {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .form-items {
    display: flex;
    flex-direction: row;
  }

  .MuiCheckbox-root {
    &:hover{
      background: none !important;
    }

    .MuiSvgIcon-root {
      fill: #000000;
    }

    .MuiCheckbox-colorSecondary.Mui-checked:hover {
      background-color: #ffffff;
    }
  }

  .form-validate {
    width: 18px;
    min-width: 18px;
    display: inline;
  }

  .form-validate-mobile {
    display: none;
  }

  .form-input {
    flex-grow: 1;
    padding-left: 20px;

    .upload-container{
      display: flex;
      justify-content: space-between;
      section{
        height: 224px;
        width: 55%;
        .photo-drop{
          height: 100%;
        }

      }
      .upload-instruction-mob{
        display: none;
      }

      .upload-instruction{
        display: flex;
        justify-content: space-between;
        width: 40%;

        .photo-instruction{
          display: flex;
          flex-direction: column;
          justify-content: space-between;


          .photo-container{
            position: relative;
            width: 108px;
            .photo{
              width: 108px;
              margin-bottom: 10px;
            }

            &.bad-example:before{
              position: absolute;
              top: -10px;
              right: -10px;
              content: url("../../assets/wrong-solid.svg");
              width: 23px;
              height: 23px;
            }

            &.good-example:before{
              position: absolute;
              top: -10px;
              right: -10px;
              content: url("../../assets/check-solid.svg");
              width: 23px;
              height: 23px;
            }
          }

          p{
            font-family: "Open Sans", sans-serif;
            font-size: 12px;
            margin-top: 3px;
            margin-bottom: 0;
          }

          .good{
            &:before{
              content: url("../../assets/check-simple.svg");
              width: 10px;
              margin-right: 10px;
            }
          }

          .bad{
            &:before{
              content: url("../../assets/wrong-simple.svg");
              width: 10px;
              margin-right: 10px;
            }
          }

        }

      }
    }

    .filter-error {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .filter-error-icon {
        width: 57px;
        min-width: 57px;
        height: 49px;

        img {
          width: 57px;
          min-width: 57px;
          height: 49px;
        }
      }

      .filter-error-text {
        padding-left: 25px;
        color: #CC0000 ;

        p {
          margin: 0;
        }

        a {
          color: #CC0000 ;
        }

        img {
          margin-top: 25px;
          width: 80px;
          height: 80px;
        }
      }
    }

    .input-field-error {
      border-bottom-color: #CC0000 !important;
      input {
        &:after {
          border-bottom-color: #CC0000;
        }
      }
  
      .MuiInput-underline {
        &:after {
          border-bottom-color: #CC0000 !important;
        }
  
        &:before {
          border-bottom-color: #CC0000 !important;
        }
      }
    }

    .form-input-field {
      flex-grow: 1;

      input {
        padding-top: 0 !important;
        padding-bottom: 4px !important;
        font-size: 16px !important;
        font-weight: 300 !important;

        &:hover {
          border-bottom: 1px;
          cursor: pointer;
        }

        &:after {
          border-bottom-color: #000000;
          transition: none;
          border-bottom-width: 1px;
        }
      }

      .MuiInput-underline {
        &:after {
          border-bottom-color: #cecece;
          transition: none;
          border-bottom-width: 1px;
        }

        &:before {
          border-bottom-color: #cecece;
          transition: none;
          border-bottom-width: 1px;
        }

        &:hover:before {
          border-bottom-color: #000;
        }

        &.Mui-focused:before,
        &.Mui-focused:after {
          border-bottom-color: #000;
        }
      }
    }

    .newsletter-input {
      margin-top: 20px;
      margin-left: 34px;

      .MuiCheckbox-root {
        margin-left: -33px !important;
      }
    }
  }

  .form-errors {
    margin-left: 15px;
    width: 12.41vw;
    min-width: 12.41vw;
  }

  .field-error {
    border: solid 1px #CC0000 !important;
    color: red;
  }
}

.message-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.error-text {
  color: #CC0000;
  margin: 0;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

.photo-drop {
  background-color: #f1f1f1;;
  height: 200px;
  border: dashed 2px #cecece;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hide-div {
    display: none;
  }

  .upload-text {
    margin-top : 30px;
    
    p {
      margin: 0;
      text-align: left;
    }
  }
}

.thumbnail-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p{
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .thumbnail {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-right: 8px;
    height: 100%;
    box-sizing: border-box;

    .thumbnail-inner {
      display: flex;
      max-height: 180px;
      width: auto;
      overflow: hidden;

      .thumbnail-image {
        display: block;
        max-height: 180px;
        width: auto;
      }
    }
  }
}

.student-images {
  margin: auto;
  background-color: #ffffff;
  height: auto;
  margin-top: 40px;
  overflow: hidden;

  .student-images-content {
    display: flex;
    flex-direction: column;

    .image-container {
      display: flex;
      justify-content: center;
      
      .student-image {
        max-width: 240px;
        min-width: 40px;
        height: auto;
        padding: 20px 6px;
        display:  flex;
        flex-grow: 0;
        flex-shrink: 1;
        width: 100%;
        flex-flow: row nowrap;
        align-items: center;

        &:first-of-type {
          padding-left: 12px;
        }

        &:last-of-type {
          padding-right: 12px;
        }

        &:nth-child(n+4) {
            display: none; 
        }

        img {
          max-width: 240px;
          min-width: 40px;
          height: auto;
          width: 100%;
          display:  flex;
          flex-grow: 0;
          flex-shrink: 1;
        }
      }
    }

    .hashtag {
      margin: 0;

      p {
        font-size: 24px;
        font-family: 'BebasNeue', sans-serif;
        letter-spacing: 0.5px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        width: 2000px;
        margin-top: 0;
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  .headline .headline-content h1 {
    font-size: 7.25vw;
  }

  .form-block {
    padding: 0;
    .form-info {
      h3 {
        font-size: 19px;
      }
    }
  }

  .submit-btn-container {
    padding: 0 6.94vw;
  }

  .form-section,
  .form-container,
  .form-field,
  .form-items {
    .form-input {

      .upload-container{
        flex-direction: column;

        section{
          width: 100%;
        }
        .upload-instruction{
          display: none;
        }
        .upload-instruction-mob{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto 20px;
          width: 75%;

          .photo-instruction{
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            .photo-container{
              position: relative;
              width: 108px;
              .photo{
                width: 108px;
                margin-bottom: 10px;
              }

              &.bad-example:before{
                position: absolute;
                top: -10px;
                right: -10px;
                content: url("../../assets/wrong-solid.svg");
                width: 23px;
                height: 23px;
              }

              &.good-example:before{
                position: absolute;
                top: -10px;
                right: -10px;
                content: url("../../assets/check-solid.svg");
                width: 23px;
                height: 23px;
              }
            }

            p{
              font-family: "Open Sans", sans-serif;
              font-size: 12px;
              margin-top: 3px;
              margin-bottom: 0;
            }

            .good{
              &:before{
                content: url("../../assets/check-simple.svg");
                width: 10px;
                margin-right: 10px;
              }
            }

            .bad{
              &:before{
                content: url("../../assets/wrong-simple.svg");
                width: 10px;
                margin-right: 10px;
              }
            }

          }
        }
      }
    }
  }


}

@media only screen and (min-width: 768px) {
  .student-images .student-images-content .image-container .student-image {
    &:nth-child(n+4) {
        display: flex; 
    }
  }

  .form-block {
    margin: 20px auto; 

    &:last-of-type {
      margin-top: 50px;
      margin-bottom: 90px;
    }

    .form-info {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0;
  }

  .headline{
    margin: 0;
    .headline-content {
      padding: 0 20px;

      h1 {
        font-size: 7.25vw;
      }
    }
  }

  .form-success {
    .headline{
      margin-top: 15px;
    }

    .success-text {
      margin-top: 40px;
      padding: 0 20px;
    }
  }

  .form-block {
    .form-info {
      width: 100%;
      padding: 0 20px;
    }

    .form-section {
      width: 100%;
      .form-container {
        width: 90%;

        .form-field {
          padding-left: 0;
          padding-right: 0;
          margin-top: 35px;

          &:first-of-type {
            margin-top: 0;
          }

          .message-input textarea {
            padding: 15px;
          }

          .filter-error-icon {
            display: none;
          }

          .filter-error-text {
            padding-left: 0;
            display: flex;
            flex-direction: column;

            img {
              align-self: center;
            }
          }
        }
      }
    }

  }

  .submit-btn-container {
    button {
      width: 100%;
    }

    p {
      width: 100%;
    }
  }

  .form-field {
    .form-label {
      padding-left: 0;
    }

    .form-items {
      flex-direction: column;
  
      .form-validate {
        display: none;
      }

      .form-validate-mobile {
        display: inline;
        padding-right: 5px;
      }

      .form-input {
        padding-left: 0;
      }

      .form-errors {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .headline .headline-content h1 {
    font-size: 50px;
  }
}
