.home-container {
  .headline {
    max-width: 1440px;
    margin: auto;

    .headline-content {
      margin-top: 40px;
      max-width: 1240px;
      text-align: center;

      h1{
        margin: 0;
        font-size: 8.90vw;
        line-height: 0.83;
        font-family: 'BebasNeue', sans-serif;
        text-transform: uppercase;
        justify-content: center;
        align-content: center;
        font-weight: normal;
        margin-bottom: 40px;

        span {
          font-weight: bold;
        }
      }
    }
  }

  .student-images {
    margin: auto;
    background-color: #ffffff;
    height: auto;
    margin-top: 40px;
    overflow: hidden;

    .student-images-content {
      display: flex;
      flex-direction: column;

      .image-container {
        display: flex;
        justify-content: center;
        
        .student-image {
          max-width: 240px;
          min-width: 40px;
          height: auto;
          padding: 20px 6px;
          display:  flex;
          flex-grow: 0;
          flex-shrink: 1;
          width: 100%;
          flex-flow: row nowrap;
          align-items: center;

          &:first-of-type {
            padding-left: 12px;
          }

          &:last-of-type {
            padding-right: 12px;
          }

          &:nth-child(n+4) {
              display: none; 
          }

          img {
            max-width: 240px;
            min-width: 40px;
            height: auto;
            width: 100%;
            display:  flex;
            flex-grow: 0;
            flex-shrink: 1;
          }
        }
      }

      .hashtag {
        margin: 0;

        p {
          font-size: 24px;
          font-family: 'BebasNeue', sans-serif;
          letter-spacing: 0.5px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          width: 2000px;
          margin-top: 0;
        }
      }
    }
  }

  .description {
    max-width: 1440px;
    margin: auto;

    .description-content {
      padding: 0 20px;
      margin-top: 40px;
      max-width: 1440px;

      p {
        font-size: 16px;
        line-height: 1.4;
        font-family: 'Open Sans', sans-serif;
        margin-top: 0;
      }
    }
  }

  .directions {
    max-width: 1440px;
    margin: auto;

    .directions-content {
      max-width: 1440px;
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .directions-box {
        width: 375px;
        height: 100%;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 40px;

        &:nth-child(2) {
          margin-left: 20px;
          margin-right: 20px;
        }

        .directions-container {
          width: 375px;

          .step {
            display: flex;
            flex-shrink: 0;
            font-weight: 800;
            line-height: 1;
            font-family: 'Open Sans', sans-serif;
            padding-bottom: 20px;
            border-bottom: 1px solid #cecece;
            padding-left: 30px;
            margin-top: 30px;
            padding-right: 30px;
            font-size: 24px;

            .step-number {
              font-size: 48px;
              line-height: 0.96;
            }
    
            h3 {
              font-weight: 800;
              font-size: 24px;
              line-height: 1.08;
              margin: 0;
            }
    
            &-number {
              font-size: 48px;
            }
    
            &-name {
              font-size: 26px;
              padding-left: 26px;
            }
          }
    
          .step-directions {
            margin-top: 32px;
            margin-left: 30px;
            margin-right: 30px;
    
            p {
              font-size: 16px;
              font-family: 'Open Sans', sans-serif;
              line-height: 1.5;
              font-weight: 600;
            }

            a:hover {
              text-decoration: none;
              opacity: 0.7;
            }

            ul {
              padding-left: 30px; 
              margin-bottom: 40px;

              li {
                font-size: 16px;
                line-height: 1.5;
                padding-left: 15px;
                font-weight: 600;
                margin-top: 5px;
              }
            }

            .snapchat-link {
              text-decoration: underline;
            }

            .snapchat {
              display: flex;
              flex-direction: column;
              align-items: center;

              p{
                font-size: 14px;
                font-weight: 600;
                color: #4a4a4a;
                text-align: center;
                line-height: 2;
              }

              .snapchat-icon {
                margin: 10px 0 40px;
                width: 100px;
                height: 100px;
              }

              a {
                text-decoration: none;
                margin-bottom: 40px;
              }

              .snapchat-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 212px;
                height: 40px;
                border-radius: 2px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                background-color: #ffffff;
                font-weight: bold;
                font-size: 15px;
                line-height: 1.6;
              }
            }
          }

          .step-two {
            p {
              font-size: 16px;
              line-height: 1.5;
              font-weight: 600;
            }
          }

          .step-three {
            p {
              font-size: 16px;
              line-height: 1.5;
              font-weight: 600;
              margin: 0;

              &:last-of-type {
                font-size: 16px;
                line-height: 1.33;
                margin: 0;
              }
            }

            a {
              text-decoration: none;

              &:hover {
                cursor: pointer;
              }
            }

            .upload-button-link:hover {
              opacity: 1;
            }

            .upload-button {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2px;
              margin-top: 47px;
              height: 64px;
              background-color: #000000;
              color: #ffffff;
              font-size: 20px;
              font-weight: 800;
              font-family: 'Open Sans', sans-serif;
              margin-bottom: 30px;

              &:hover {
                color: #000000;
                background-color: #ffffff;
                border: solid 2px #000000;
                border-radius: 2px;
              }
            }
          }

          .step-images {
            margin-top: 34px;
            justify-content: center;

            img {
              height: auto;
              width: 345px;
              margin-left: auto;
              margin-right: auto;
              display: block;
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .hashtag {
    max-width: 1440px;
    margin: auto;

    .hashtag-content {
      max-width: 1240px;
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }

      h3 {
        font-family: 'BebasNeue', sans-serif;
        font-size: 32px;
        font-weight: bold;
        font-weight: 800;
        margin: 0;
      }
    }
  }

  .launch {
    max-width: 1440px;
    margin: auto;

    .launch-content {
      padding: 30px 20px;
      max-width: 1440px;
      display: flex;
      flex-direction: column;
      border: solid 1px #979797;
      background-color: #ffffff;
      border-radius: 2px;
      margin: 0 6.94vw;
      align-content: center;
      align-items: center;
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      .graduate-text {
        margin-top: 30px;

        p {
          font-size: 16px;
          line-height: 1.88;
          font-family: 'Open Sans', sans-serif;
          margin: 0;

          &:first-of-type {
            display: none;
          }
        }

        a:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }

      .graduate-image {
        img {
          width: 233px;
          height: 108px;
        }
      }
    }
  }

  .print {
    max-width: 1440px;
    margin: auto;

    .print-content {
      padding: 0 20px;
      margin-top: 40px;
      padding-bottom: 40px;
      max-width: 1240px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;

      .print-headline {
        text-align: left;
        width: 100%;
        margin-top: 40px;
      }

      h3 {
        margin-top: 0;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 800;
        line-height: 1;
      }

      .print-image {
        width: 100%;

        img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }

      .print-text {
        width: 100%;
        padding-top: 20px;
        margin: 0;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;

        .print-headline {
          display: none;
        }

        a {
          text-decoration: none;
  
          &:hover {
            text-decoration: none;
            opacity: 0.7;
          }
        }

        p {
          font-size: 16px;
          line-height: 1.5;
          font-family: 'Open Sans', sans-serif;

          &:first-of-type {
            margin-top: 0;
          }
        }

        .print-hashtag {
          font-weight: 800;
        }
      }
    }
  }
}

@media only screen and (min-width: 400px) {
  .home-container {
    .directions .directions-content {
      .directions-box {
        width: 400px;

        .directions-container {
          width: 400px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .home-container {
    .headline .headline-content h1 {
      font-size: 7.25vw;
      margin-top: 48px;
      margin-bottom: 70px;
    }

    .image-bar {
      margin-top: 70px;
    }

    .student-images {
      margin-top: 70px;

      .student-images-content .image-container .student-image {
        &:nth-child(n+4) {
            display: flex; 
        }
      }
    }

    .hashtag .hashtag-content {
      padding: 0 26.11vw;
      margin-top: 40px;

      h3 {
        font-size: 3.33vw;
      }
    }

    .description .description-content {
      padding: 0 14.23vw;
      margin-top: 70px;

      p{
        font-size: 20px;
      }
    }

    .launch .launch-content {
      padding: 30px 6.29vw;
      margin-top: 100px;
      flex-direction: row;

      .graduate-text {
        margin-top: 0;
        padding-left: 6.59vw;

        p:first-of-type {
          display: inline;
        }
      }
    }  

    .print .print-content {
      .print-text {
        padding-left: 8.68vw;
        padding-right: 8.68vw;

        h3 {
          font-size: 24px;
        }
      }
    }

      .directions .directions-content {
      margin-top: 100px;

      .directions-box {
        height: 600px;
    
        .directions-container {
          width: 400px;
    
          .step-images {
            margin-top: 88px;
          }
        }
      }
    }

    .print .print-content {
      padding-bottom: 112px;
      margin-top: 100px;

      .print-text {
        padding-top: 40px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .home-container {
    .print .print-content {
      justify-content: flex-start;

      .print-headline {
        display: none;
      }

      .print-image {
        width: 42.36vw;
        max-width: 610px;

        img {
          min-width: 400px;
          max-width: 610px;
          width: 100%;
          height: auto;
        }
      }

      .print-text {
        width: 400px;
        padding-right: 0;
        margin-top: 0;

        .print-headline {
          display: inline;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .home-container {
    .headline .headline-content h1 {
      font-size: 120px;
    }

    .hashtag .hashtag-content h3 {
      font-size: 50px;
    }

    .print .print-content .print-text {
      padding-left: 125px;
    }
  }
}