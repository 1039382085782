.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.zoomed-dialog .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 1) !important;
}

.MuiPaper-root {
  overflow: hidden !important;
}

.close-button {
  top: 20px;
  right: 30px;
  position: fixed;
  z-index: 9999999;

  &:hover {
    cursor: pointer;
  }
}

.zoomed-social-buttons {
  top: 90%;
  width: 100%;
  position: fixed; 
  z-index: 9999999;
  display: flex;
  justify-self: center;
  justify-content: center;
  margin: 0 -20px;

  .download {
    width: 15px;
    height: 18px;
    margin-right: 30px;
  }

  .twitter {
    width: 18px;
    height: 18px;
    margin-right: 30px;
  }

  .facebook {
    width: 12px;
    height: 18px;
  }
}

.arrow-left {
  top: 93%;
  left: 30px;
  position: fixed;
  z-index: 9999999;
  width: 35px;
  height: 35px;

  &:hover {
    cursor: pointer;
  }
}

.arrow-right {
  top: 93%;
  right: 30px;
  position: fixed;
  z-index: 999999;
  width: 35px;
  height: 35px;

  &:hover {
    cursor: pointer;
  }
}

.zoomed-dialog .dialog-container {
  padding: 0 !important;
  height: auto;
}

.dialog-container {
  width: 90%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;

  .zoomed {
    height: auto;

    .zoomed-student {
      height: 60vh;
      width: auto;
    }
  }

  .student-image {
    height: auto;
    flex-direction: row;
    display: flex;

    .yearbook-image {
      display: none;
    }

    .mobile-image-container {
      position: relative;

      .zoom-container {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 9px;
        right: 1px;

        .zoom-button {
          max-width: 14px !important;
          min-width: unset !important;
          color: green;
          height: 14px;
          position: relative;
        }
      }
    }

    .student-info-mobile {
      display: inline;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      h2 {
        margin: 0;
        font-size: 24px;
        text-transform: uppercase;
      }

      p {
        margin: 0;
        font-size: 16px;
        padding-right: 10px;
      }
    }

    img {
      min-width: 150px;
      height: 220px;
    }

  }

  .student-info {
    display: flex;
    flex-direction: column;

    .student-name {
      font-size: 40px;
      font-family: 'BebasNeue', sans-serif;
      margin-top: 50px;
      margin-bottom: 0;
      display: none;
    }
  
    .school-name {
      margin: 0 0 30px 0;
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;
      display: none;
    }
  
    .message {
      margin: 10px 0 0 0;
      font-size: 13px;
      font-style: italic;
      line-height: 1.54;
      font-family: 'Open Sans', sans-serif;
    }

    .social-buttons {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      padding-top: 20px;

      a {
        margin-right: 20px;
        height: 18px;

        &:hover {
          cursor: pointer;
        }
      }

      .download {
        width: 15px;
        height: 18px;
        margin-right: 20px;

        &:hover {
          cursor: pointer;
        }
      }

      .twitter {
        width: 19px;
        height: 18px;
      }

      .facebook {
        width: 12px;
        height: 18px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .dialog-container {
    width: 500px;
    max-width: 500px;

    .student-image .mobile-image-container .zoom-container {
      display: none;
    }
  }
}

@media only screen and (max-width: 420px) {
  .dialog-container .student-image .student-info-mobile {
    h2 {
      font-size: 19px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .arrow-left {
    top: 50%;
    left: 30px;
    width: 60px;
    height: 60px;
  }
  
  .arrow-right {
    top: 50%;
    right: 30px;
    width: 60px;
    height: 60px;
  }

  .dialog-container {
    flex-direction: row;
    padding: 0;
    width: 900px;
    max-width: 900px;
    height: 580px;

    .student-image {
      width: 400px;
      width: 400px;
      height: 584px;

      .yearbook-image-mobile {
        display: none;
      }

      .yearbook-image {
        display: inline;
      }

      .student-info-mobile {
        display: none;
      }

      img {
        width: 400px;
        height: 584px;
      }
    }

    .student-info {
      padding-left: 40px;
      padding-right: 40px;

      .student-name {
        display: inline;
      }

      .school-name {
        display: inline;
      }

      .message {
        margin: 0;
        font-size: 18px;
        line-height: 1.67;
      }

      .social-buttons {
        padding-bottom: 40px;

        a {
          height: 22px;
        }

        .download {
          width: 18px;
          height: 22px;
        }

        .twitter {
          width: 22px;
          height: 22px;
        }
  
        .facebook {
          width: 14px;
          height: 22px;
        }
      }
    }
  }
}