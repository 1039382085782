.footer {
  background-color: #ffffff;
  border-top: 1px solid #cecece;
  bottom: 0;
  width: 100%;
  
  .footer-container {
    max-width: 1440px;
    margin: auto;

    .footer-content {
      max-width: 1240px;
      margin: auto;
      display: flex;
      align-items: center;
      padding: 35px 20px 35px 20px;
      background-color: red($color: #000000);
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
  
      .logo {
        color: black;
        width: 151px;
        display: none;
        justify-content: center;
  
        img {
          width: auto;
          height: 24px;
        }
      }
  
      .right-content {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
  
      .menu-options {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        width: 100%;
        order: 2;
        margin-top: 35px;
  
        a {
          margin-right: 10px;
          font-size: 12px;
          font-weight: 600;
          color: #000000;
          text-decoration: none;

          &:hover {
            text-shadow: 0px 0px 0.5px #000000;
          }
        }
      }
  
      .copyright {
        display: flex;
        justify-content: center;
        margin-right: 20px;
        order: 4;
        margin-top: 35px;
  
        p {
          margin-right: 10px;
          font-size: 10px;
        }
      }
  
      .build-logo {
        order: 1;
        display: flex;
        align-content: center;
        justify-content: center;

        .build-link {
          display: flex;
          align-items: center;
        }
  
        .logo {
          display: flex;
          justify-content: space-between;
          width: 200px;
        }
  
        p {
          display: inline-flex;
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
  
      a {
        font-size: 12px;
        margin-bottom: 10px;
  
        &:hover{
          cursor: pointer;
        }
  
        &:last-of-type {
          display:flex;
        }
      }
  
      p {
        text-transform: uppercase;
        font-size: 12px;
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .footer .footer-container .footer-content {
    flex-direction: row;
    padding: 65px 20px 65px 20px;
    font-family: 'Open Sans', sans-serif;

    .title {
      font-size: 3.402vw;
    }

    .logo {
      display: flex;
      justify-content: space-between;

      a {
        margin-right: 65px;
      }

      img {
        width: auto;
        height: 28px;
      }
    }

    .right-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: auto;
    }

    .menu-options {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      order: 1;
      margin-top: 0;
      flex-wrap: nowrap;
      flex-direction: row;
      width: auto;

      a {
        margin-right: 10px;
        font-weight: 400;
      }
    }

    .copyright {
      display: flex;
      margin-right: 20px;
      order: 2;
      margin-top: 0;
      flex-direction: row;
      flex-wrap: nowrap;

      a {
        margin-right: 10px;
      }
    }

    .build-logo {
      order: 3;
      display: flex;
      align-content: center;

      .build-link {
        display: flex;
        align-items: flex-start;
      }

      .logo {
        display: none;
      }
    }
  }
}