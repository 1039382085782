
@font-face {
  font-family: 'BebasNeue';
  font-weight: 800;
  src: local('BebasNeue-Bold'), url(./fonts/BebasNeue/BebasNeue-Bold.otf) format('opentype');
}

// @font-face {
//   font-family: 'BebasNeue';
//   src: local('BebasNeue-Regular'), url(./fonts/BebasNeue/BebasNeue-Regular.otf) format('opentype');
// }

// @font-face {
//   font-family: 'OpenSans';
//   src: local('OpenSans-Regular'), url(./fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
// }

// @font-face {
//   font-family: 'OpenSans';
//   font-weight: 800;
//   src: local('OpenSans-Bold'), url(./fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
// }

// @font-face {
//   font-family: 'OpenSans';
//   font-weight: 800;
//   src: local('OpenSans-BoldItalic'), url(./fonts/OpenSans/OpenSans-BoldItalic.ttf) format('truetype');
// }

.app-container {
  display: block;
  position: relative;
  min-height: 100vh;

  &::after {
    content: "";
    background-image: url('./backdrop.jpg');
    background-size: 1440px 2160px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;   
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
  }

  .MuiInputBase-root {
    font-family: 'Open Sans', sans-serif;
  }
  .flex-90 {
    flex: 0 0 90%;
  }
  .d-none {
    display: none;
  }
}