.terms {
  max-width: 1440px;
  margin: auto;

  .title {
    padding-left: 6.944vw;
    padding-right: 6.944vw;
    margin-top: 17px;
    max-width: 1440px;
    text-align: center;

    h1{
      margin: 0;
      line-height: 1.11;
      font-weight: bold;
      font-family: 'BebasNeue', sans-serif;
      text-transform: uppercase;
      justify-content: center;
      align-content: center;
      font-size: 38px;
    }
  }

  &-content {
    padding: 0 20px;
    margin-top: 28px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    

    h2 {
      font-size: 22px;
      font-weight: 800;
      line-height: 1.27;
      font-family: 'Open Sans', sans-serif;
    }

    p {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 16px;
    }

    .terms-header {
      font-weight: bold;
    }

    ol {
      margin-top: 0;
      margin-bottom: 30px;
      padding-left: 30px;
      font-size: 12px;
      margin-left: 20px;

        li {
          font-size: 16px;
          padding-left: 15px;
          margin-top: 15px;
      }
    }

    .location {
      padding-left: 30px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .terms {
    .title h1 {
      font-size: 64px;
      margin-top: 47px;
    }

    &-content {
      padding: 0 14.23vw;
      margin-top: 70px;

      .terms-header {
        font-size: 26px;
      }

      h2 {
        font-size: 26px;
        margin-bottom: 30px;
      }

      p {
        font-size: 16px;
      }

      ol {
        font-size: 14px;

        li {
          font-size: 16px;
        }
      }
    }
  }
}