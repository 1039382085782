.gallery-container {
  width: 90%;
  margin: 0 auto;

  h1 {
    font-family: 'BebasNeue', sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 64px;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    text-align: center;
  }

  .video-container{
    margin: 50px 0 20px 0;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    .video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .video-text {
    text-align: left;
    margin-top: 0;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 50px;

    div{
      display: flex;
      align-items: center;
      padding-left: 7vw;

      p {
        text-align: left;

        &:last-of-type {
          padding-bottom: 30px;
        }
      }
    }

    .photo-mob{
      display: none;
    }
  }

  a{
    text-decoration: none;
    p{
      font-family: 'BebasNeue', sans-serif;
      font-size: 50px;
      font-weight: bold;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .gallery-container{
    h1{
      font-size: 38px;
    }

    p{
      font-size: 16px;
    }

    .info {
      flex-direction: column;
      .photo{
        display: none;
      }

      .photo-mob{
        display: block;
        width: 98%;
      }
    }

    a{
      p{
        font-size: 25px;
      }
    }
  }

}
