.privacy {
  max-width: 1440px;
  margin: auto;

  .title {
    padding-left: 6.944vw;
    padding-right: 6.944vw;
    margin-top: 40px;
    max-width: 1440px;
    text-align: center;

    h1{
      margin: 0;
      line-height: 0.6;
      font-size: 38px;
      font-weight: bold;
      font-family: 'BebasNeue', sans-serif;
      text-transform: uppercase;
      justify-content: center;
      align-content: center;
    }
  }

  &-content {
    padding: 0 20px;
    margin-top: 30px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    
    p {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 16px;
    }

    .privacy-header {
      font-weight: bold;
      font-size: 22px;
      margin-top: 50px;
      line-height: 1.3;
    }

    ul {
      list-style: none;
      margin-top: 0;
      margin-bottom: 30px;
      padding-left: 30px;
      font-size: 16px;

        li:before {
          content: "•";
          font-size: 16px;
          padding-right: 25px;
      }
    }

    ol {
      font-size: 16px;
      padding-left: 30px;

      li {
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .privacy {
    .title h1 {
      font-size: 64px;
      margin-top: 82px;
    }

    &-content {
      padding: 0 14.23vw;
      margin-top: 50px;
      font-size: 16px;

      p {
        font-size: 16px;
      }

      ul, ol {
        font-size: 16px;
      }

      .privacy-header {
        font-size: 26px;
      }
    }
  }
}