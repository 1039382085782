.eucn { 
  background: #202020; 
  position: fixed; 
  bottom: 0; 
  left: 0; 
  right: 0; 
  font-size: 0.8em; 
  font-family: "Open Sans", sans-serif;
  font-weight: 400; 
  text-align: center; 
  z-index: 2147483647; 
  overflow: hidden; 
  height: 0; 
  opacity: 0; 
  -webkit-transition: opacity 0.5s ease-out, height 0.5s ease-out; 
  transition: opacity 0.5s ease-out, height 0.5s ease-out; 
}

.eucn .main-container { 
  box-sizing: border-box; 
  max-width: 820px; 
  margin: 0 auto; 
  height: 100%; 
  padding: 0 15px; 
  display: -webkit-box; 
  display: flex; 
  -webkit-box-align: center; 
  align-items: center; 
  justify-content: space-evenly; 
}

.eucn a { 
  color: #d8d8d8; 
  padding: 5px 0; 
  text-decoration: underline; 
}

.eucn--show { 
  opacity: 1; 
  height: auto; 
}

.eucn__notice {
  color: #cecece; 
  -webkit-text-size-adjust: 100%; 
  line-height: 1.3; 
  padding-right: 2%; 
}

.eucn__accept {
  color: #fafafa; 
  background: #666; 
  font-weight: 700; 
  cursor: pointer; 
  height: 24px; 
  font-size: 12px; 
  padding: 0 10px; 
  border: 0; 
}