.about-container {
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
}

.react-multi-carousel-list {
  margin-top: 0;
}

.about-title{
  width: 70%;
  margin: 0 auto;
  font-family: 'BebasNeue', sans-serif;
  margin-top: 90px;

  h1{
    font-size: 64px;
    text-align: center;
    line-height: 0.78;
    margin-bottom: 70px;
  }

  p{
    font-size: 20px;
    line-height: 1.4;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 30px;
  }
}

.about-content {
  max-width: 1240px;
  margin: auto;

  .about-info-block{
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
    //flex-wrap: wrap;
  
    img{
      width: 50%;
      height: 55%;
      min-width: 50%;
    }
  
    .about-text-container{
      display: flex;
      align-items: center;
  
      .about-text{
        padding-left: 7.98vw;
        padding-right: 7.98vw;
  
        img{
          display: none;
        }
  
        h2{
          font-size: 26px;
          font-weight: 800;
          line-height: 1;
          text-transform: uppercase;
        }
  
        p{
          font-size: 1rem;
          line-height: 1.5;
        }

        a:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 90px;
    }
  }
}


@media only screen and (max-width: 500px) {
  .about-title{
    width: 80%;
    margin: 0 auto;
    font-size: 33px;
    font-weight: bold;
    line-height: 0.91;
    font-family: 'BebasNeue', sans-serif;

    h1{
      text-align: left;
    }

    p{
      font-size: 16px;
      line-height: 1.6;
      font-weight: normal;

    }
  }

  .about-info-block{
    padding: 1.94vw;
  }

}

@media only screen and (max-width: 1024px) {
  .about-container {
    .about-title {
      margin-top: 40px;
      width: 100%;

      h1 {
        margin-bottom: 20px;
        text-align: left;
        font-size: 38px;
        line-height: 1.11;
      }

      p {
        font-size: 16px;
      }
    }

    .about-content {
      .about-title {
        width: 100%;
        margin-top: 40px;

        h1 {
          text-align: left;
          font-size: 38px;
          line-height: 1.11;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
        }
      }

      .about-info-block {
        flex-direction: column;
        margin-top: 40px;
        padding: 0;

        img {
          display: none;
        }

        .about-text-container {
          .about-text {
            padding: 0;

            img {
              display: block;
              width: 100%;
            }

            h2 {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px){
  .about-info-block {
    padding: 3.94vw;
  }

}
